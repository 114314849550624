import {
  createContext,
  useState,
  useCallback,
} from 'react';

import { DialogueType } from 'types/types';

export type DialogueState = {
  presentedDialogueType: DialogueType | null,
};

type DialogueContextType = {
  dialogueState: DialogueState,
  openDialogue: (dialogueType: DialogueType) => void,
  closeDialogue: () => void,
};

const initialDialogueState: DialogueState = {
  presentedDialogueType: null,
};

const useDialogue= ({onDialogueClose = () => {}}): DialogueContextType =>  {
  const [dialogueState, setDialogueState] = useState<DialogueState>(initialDialogueState);

  const openDialogue = useCallback((dialogueType: DialogueType) => {
    setDialogueState(state => ({
      ...state,
      presentedDialogueType: dialogueType,
    }));
  }, []);

  const closeDialogue = useCallback(() => {
    setDialogueState(state => ({
      ...state,
      presentedDialogueType: null,
    }));

    onDialogueClose() 
  }, [onDialogueClose]);

  return {
    dialogueState,
    openDialogue,
    closeDialogue,
  };
};

export const DialogueContext = createContext<DialogueContextType>({
  dialogueState: initialDialogueState,
  openDialogue: (dialogueType: DialogueType) => {},
  closeDialogue: () => {},
});

export default useDialogue;
