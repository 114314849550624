class SessionService {
  static setItemToSession(key: string, value: string) {
    window.sessionStorage.setItem(key, value);
  }

  static getItemFromSession(key: string): string | null {
    return window.sessionStorage.getItem(key);
  }
}

export default SessionService;
