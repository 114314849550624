import { createContext, useState, FC, useContext } from 'react';
import { SearchSuggestion } from './types';
import { UrlObject } from 'url';

type State = {
  searchInputValue: string;
  debouncedSearchInputValue: string;
  searchSuggestionList: SearchSuggestion[] | undefined;
  showSearchSuggestions: boolean;
  selectedSuggestion: SearchSuggestion | null;
  isLoadingSearchSuggestionList: boolean;
  noResultsFound: boolean;
  showInspirationCategories: boolean;
  urlObject?: UrlObject;
};

type Dispatch = (newState: Partial<State>) => void;

type SearchBarProviderProps = {
  children: React.ReactNode;
};

const SearchBarContext = createContext<
  { state: State; setState: Dispatch } | undefined
>(undefined);

const initialState: State = {
  searchInputValue: '',
  debouncedSearchInputValue: '',
  searchSuggestionList: undefined,
  showSearchSuggestions: false,
  selectedSuggestion: null,
  isLoadingSearchSuggestionList: false,
  noResultsFound: false,
  showInspirationCategories: false,
};

const SearchBarProvider: FC<SearchBarProviderProps> = ({ children }) => {
  const [state, setState] = useState<State>(initialState);

  const dispatchSliceOfState = (newState: Partial<State>) => {
    setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  return (
    <SearchBarContext.Provider
      value={{ state, setState: dispatchSliceOfState }}
    >
      {children}
    </SearchBarContext.Provider>
  );
};

const useSearchBarContext = () => {
  const context = useContext(SearchBarContext);
  if (context === undefined) {
    throw new Error(
      'useSearchBarContext must be used within a SearchBarProvider'
    );
  }
  return context;
};

export { SearchBarProvider, useSearchBarContext };
