import { WEB_APP_ROUTES } from 'data/constants';
import {
  useState,
  useCallback,
  createContext,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from 'react';

type SignInDialogueStateType = {
  redirectTo?: typeof WEB_APP_ROUTES[number];
  email: string;
  password: string;
  keepMeSignedIn: boolean;
  isLoading: boolean;
  signInError: boolean;
  errors: {
    email: boolean;
    password: boolean;
  };
};

type SignInContextType = {
  signInState: SignInDialogueStateType;
  setState: Dispatch<SetStateAction<SignInDialogueStateType>>;
  handleTextChange: ChangeEventHandler<HTMLInputElement>;
  handleCheckBoxChange: (name: string, value: boolean) => void;
};

const initialSignInDialogueState: SignInDialogueStateType = {
  email: '',
  password: '',
  keepMeSignedIn: true,
  isLoading: false,
  signInError: false,
  errors: {
    email: false,
    password: false,
  },
};

const useSignIn: () => SignInContextType = () => {
  const [state, setState] = useState<SignInDialogueStateType>(
    initialSignInDialogueState
  );

  const handleTextChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    e => {
      const key = e.target.name as keyof SignInDialogueStateType;
      setState(state => ({
        ...state,
        [key]: e.target.value,
        errors: {
          ...initialSignInDialogueState.errors,
        },
      }));
    },
    []
  );

  const handleCheckBoxChange = useCallback((name: string, value: boolean) => {
    const key = name as keyof SignInDialogueStateType;
    setState(state => ({
      ...state,
      [key]: value,
    }));
  }, []);

  return {
    signInState: state,
    setState,
    handleTextChange,
    handleCheckBoxChange,
  };
};

export const SignInContext = createContext<SignInContextType>({
  signInState: initialSignInDialogueState,
  setState: () => {},
  handleTextChange: () => {},
  handleCheckBoxChange: () => {},
});

export default useSignIn;
