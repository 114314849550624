import { createTheme, ThemeOptions } from '@mui/material';

import { colorConstants } from 'data/constants';

const theme: ThemeOptions = {
  typography: {
    fontFamily: 'Avenir Next',
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          fontSize: '.875rem',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid lightGray'
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "none",
          '& fieldset': {
            borderRadius: '5px',
            border: `1px solid ${colorConstants.lightGray}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colorConstants.gray,
            borderWidth: '1px',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          border: `1px solid ${colorConstants.gray}`,
          borderRadius: '0 0 5px 5px',
          paddingTop: 0,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          borderTop: "none",
        }
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '0 0 5px 5px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          padding: '.5rem',
          '&:hover': {
            backgroundColor: colorConstants.lightGray,
          },
          '&.Mui-selected': {
            backgroundColor: colorConstants.lightGray,
          },
          '&.Mui-selected:hover': {
            backgroundColor: colorConstants.lightGray,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
};

const muiTheme = createTheme(theme);

export default muiTheme;
