import { JoinUsDialogueStateType } from 'customHooks/useJoinUs/useJoinUs';
import { Dispatch, SetStateAction, useEffect } from 'react';
import MembershipService from 'services/MembershipService/MembershipService';
import { CurrencyType } from 'types/types';

const useDefaultCurrency = ({
  setState,
  currencies,
  shouldFetch = true,
}: {
  setState: Dispatch<SetStateAction<JoinUsDialogueStateType>>;
  currencies: CurrencyType[];
  shouldFetch: boolean;
}) => {
  useEffect(() => {
    if (!shouldFetch) {
      return;
    }

    const getDefaultCurrency = async () => {
      const currency = await MembershipService.getDefaultCurrency(currencies);

      setState(state => ({
        ...state,
        currency: currency.code,
        price: {
          ...state.price,
          symbol: currency.symbol,
        },
      }));
    };

    getDefaultCurrency();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useDefaultCurrency;
