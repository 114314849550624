import { defaultCurrencyCode, membershipCategoryOptions, membershipOptions, webserviceEndpoints } from 'data/constants';
import WebService, { ApiServerWebServiceOption, ClientWebServiceOption } from 'services/WebService/WebService';
import { CurrencyType, MemberShipCategoryType, MembershipIdType, SupportedCurrencyCode } from 'types/types';

type PriceResponseType = {
  currency: SupportedCurrencyCode;
  symbol: string;
  price: number;
};

type MemberShipFeatureResponseType = {
  title: string;
  summary: string;
  display_order: number;
};

const currencySearchArray = [
  {
    currencyCode: 'GBP',
    countryCodes: ['UK'],
  },
  {
    currencyCode: 'EUR',
    countryCodes: ['AT', 'BE', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'],
  },
  {
    currencyCode: 'USD',
    countryCodes: ['AI', 'AG', 'AW', 'BS', 'BB', 'BZ', 'BM', 'BQ', 'VG', 'CA', 'KY', 'CR', 'CU', 'CW', 'DM', 'DO', 'SV', 'GL', 'GD', 'GP', 'GT', 'HT', 'HN', 'JM', 'MQ', 'MX', 'PM', 'MS', 'CW', 'KN', 'NI', 'PA', 'PR', 'BQ', 'BQ', 'SX', 'KN', 'LC', 'PM', 'VC', 'TT', 'TC', 'US', 'VI', 'AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF', 'GY', 'GY', 'PY', 'PE', 'SR', 'UY', 'VE', 'AF', 'AM', 'AZ', 'BH', 'BD', 'BT', 'BN', 'KH', 'CN', 'CX', 'CC', 'IO', 'GE', 'IN', 'ID', 'IR', 'IQ', 'IL', 'JP', 'JO', 'KZ', 'KW', 'KG', 'LA', 'LB', 'MO', 'MY', 'MV', 'MN', 'MM', 'NP', 'KP', 'OM', 'PK', 'PS', 'PH', 'QA', 'SA', 'SG', 'KR', 'LK', 'SY', 'TW', 'TJ', 'TH', 'TR', 'TM', 'AE', 'UZ', 'VN', 'YE'],
  },
  {
    currencyCode: 'HKD',
    countryCodes: ['HK'],
  },
];

export type MembershipPriceResponseObjectType = {
  name: string;
  prices: PriceResponseType[];
  features: MemberShipFeatureResponseType[];
};

class MembershipService {
  static async getPricesFromAPI(): Promise<MembershipPriceResponseObjectType[]> {
    try {
      const option = new ApiServerWebServiceOption('GET', webserviceEndpoints.LE_PRICES);
      const prices = (await WebService.shared().callWebService(option)) as MembershipPriceResponseObjectType[];
      return prices;
    } catch (error) {
      return [];
    }
  }

  static async getPrices(): Promise<MembershipPriceResponseObjectType[]> {
    try {
      const option = new ClientWebServiceOption('GET', webserviceEndpoints.PRICES);
      const prices = (await WebService.shared().callWebService(option)) as MembershipPriceResponseObjectType[];
      return prices;
    } catch (error) {
      return [];
    }
  }

  static async getDefaultCurrency(currecies: CurrencyType[]): Promise<CurrencyType> {
    const getCountryNotAvailableDefaultCurrency = () => {
      const defaultCurrency = currecies.find(({ code }) => code === defaultCurrencyCode);
      if (defaultCurrency) {
        return defaultCurrency;
      }
      return currecies[0];
    };

    try {
      const cloudFareResponse = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
      const cloudFareResponseBlob = await cloudFareResponse.blob();
      const text = await cloudFareResponseBlob.text();
      let countryCode = null;
      text.split('\n').forEach(keyValueElement => {
        const [key, value] = keyValueElement.split('=');
        if (key === 'loc') {
          countryCode = value;
        }
      });

      if (countryCode === null) {
        return getCountryNotAvailableDefaultCurrency();
      }

      let searchedCurrencyCode: string | null = null;
      let i = 0;
      while (searchedCurrencyCode === null && i < currencySearchArray.length) {
        const { currencyCode, countryCodes } = currencySearchArray[i];
        if (countryCodes.includes(countryCode)) {
          searchedCurrencyCode = currencyCode;
        }
        i += 1;
      }

      if (searchedCurrencyCode === null) {
        return getCountryNotAvailableDefaultCurrency();
      }

      const searchedCurrency = currecies.find(({ code }) => code === searchedCurrencyCode);

      if (!searchedCurrency) {
        return getCountryNotAvailableDefaultCurrency();
      }

      return searchedCurrency;
    } catch (error) {
      return getCountryNotAvailableDefaultCurrency();
    }
  }

  static subscribeToNewsLetter(email: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const option = new ClientWebServiceOption('POST', webserviceEndpoints.SUBSCRIBE_TO_NEWSLETTER, {
            email,
        });
        await WebService.shared().callWebService(option);
        resolve();
      } catch (error) {
        reject();
      }
    });
  };

  static getCategoryForMemberShipId(id: MembershipIdType): MemberShipCategoryType {
    const memberShipIdCategoryDictionary: {[key: MembershipIdType]: MemberShipCategoryType} = {
      [membershipOptions.TRAVEL]: membershipCategoryOptions.LEISURE,
      [membershipOptions.BLACK]: membershipCategoryOptions.LEISURE,
      [membershipOptions.GOLD]: membershipCategoryOptions.LEISURE,
      [membershipOptions.BLACK_PLUS]: membershipCategoryOptions.LEISURE,
      [membershipOptions.DYNASTY]: membershipCategoryOptions.CORPORATE,
      [membershipOptions.DYNASTY_DINING]: membershipCategoryOptions.CORPORATE,
    };
    return memberShipIdCategoryDictionary[id];
  };
};

export default MembershipService;
