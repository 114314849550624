import {
  createContext,
  useState,
  useCallback,
} from "react";

import { UserType } from 'types/types';

type UserStateType = {
  user: UserType,
};

type UserContextType = {
  state: UserStateType,
  setUser: (key: keyof UserType, value: string) => void,
};

const initialState: UserStateType = {
  user: {
    accessToken: '',
    name: '',
  },
};

const useUser = () => {
  const [state, setState] = useState<UserStateType>(initialState);

  const setUser = useCallback<(key: keyof UserType, value: string) => void>((key, value) => {
    setState(state => ({
      ...state,
      user: {
        ...state.user,
        [key]: value,
      }
    }));
  }, []);

  return {
    state,
    setUser,
  };
};

export const UserContext = createContext<UserContextType>({
  state: initialState,
  setUser: () => {},
});

export default useUser;
