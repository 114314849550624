import Bugsnag from '@bugsnag/js';
import { PHASE_PRODUCTION_BUILD } from 'next/constants';

export function startBugsnag() {
  if (process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD) {
    Bugsnag.start({
      apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
      appVersion: process.env.NEXT_BUILD_ID,
    });
  }
}
