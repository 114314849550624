const supportedMethods = ['GET', 'POST'] as const;

export type SupportedMethodType = typeof supportedMethods[number];

interface IWebServiceOption {
  method: SupportedMethodType;
  body?: any;
  path: string;
  headers: Headers;
}

interface IWebService {
  callWebService: (webserviceOption: IWebServiceOption) => Promise<void | any>;
}

// It is called during build time and runtime from the server, it calls the Little Emperors API
export class ServerWebServiceOption implements IWebServiceOption {
  method: SupportedMethodType;
  path: string;
  body?: any;
  headers: Headers;

  constructor(
    method: SupportedMethodType,
    path: string,
    body?: any,
    contentType = 'application/json'
  ) {
    this.method = method;
    this.path = `${process.env.LITTLE_EMPERORS_API_ENDPOINT}${path}`;
    if (body) {
      this.body = JSON.stringify(body);
    }
    const headers = new Headers();
    headers.append('Content-Type', contentType);
    headers.append(
      'Authorization',
      `Bearer ${process.env.LITTLE_EMPERORS_API_KEY}`
    );
    this.headers = headers;
  }
}

// It is called during build time and runtime from the server, it calls the Little Emperors API (api endpoint)
export class ApiServerWebServiceOption implements IWebServiceOption {
  method: SupportedMethodType;
  path: string;
  body?: any;
  headers: Headers;

  constructor(
    method: SupportedMethodType,
    path: string,
    body?: any,
    contentType = 'application/json'
  ) {
    this.method = method;
    this.path = `${process.env.LITTLE_EMPERORS_API_ENDPOINT?.replace(
      'v1',
      'api'
    )}${path}`;

    if (body) {
      this.body = JSON.stringify(body);
    }
    const headers = new Headers();
    headers.append('Content-Type', contentType);
    headers.append('App-Version', 'Website');
    headers.append(
      'Authorization',
      `Bearer ${process.env.LITTLE_EMPERORS_API_KEY}`
    );
    this.headers = headers;
  }
}

// It is used directly from the browser, it calls endpoints at the NextJs internal API
export class ClientWebServiceOption implements IWebServiceOption {
  method: SupportedMethodType;
  path: string;
  body?: any;
  headers: Headers;

  constructor(
    method: SupportedMethodType,
    path: string,
    body?: any,
    contentType = 'application/json'
  ) {
    this.method = method;
    this.path = `${process.env.NEXT_PUBLIC_CLIENT_ENDPOINT}${path}`;
    if (body) {
      this.body = JSON.stringify(body);
    }
    const headers = new Headers();
    headers.append('Content-Type', contentType);
    this.headers = headers;
  }
}

class WebService implements IWebService {
  static sharedInstance: IWebService;
  static shared() {
    if (!this.sharedInstance) {
      this.sharedInstance = new WebService();
    }
    return this.sharedInstance;
  }

  public callWebService(option: IWebServiceOption) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const response = await fetch(option.path, option);

        if (!response.ok) {
          const error = await response.json();
          reject(error);
        }

        if (response.status === 204) {
          resolve();
          return;
        }

        const jsonResponse = await response.json();
        resolve(jsonResponse);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default WebService;
